import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext'; // Імпорт AuthProvider
import Login from './components/Login';
// import Home from './components/Home';
import Register from './components/Register';
import User from './components/User';
import VideoGenerationPage from './components/VideoGenerationPage';
import Test from './components/VideoGenerationPageTest';

const App = () => {
  return (
    <AuthProvider> {/* Обгортаємо додаток у AuthProvider */}
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/user" element={<User />} />
          <Route path="/generation" element={<VideoGenerationPage />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
