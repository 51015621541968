import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import BASE_URL from './config'; // Імпортуємо домен

// Створюємо контекст
const AuthContext = createContext();

// Хук для доступу до контексту
export const useAuth = () => useContext(AuthContext);

// Провайдер для обгортання компонентів і надання контексту
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Стан для зберігання користувача
  const [loading, setLoading] = useState(false); // Стан завантаження
  const [error, setError] = useState(null); // Стан для помилок

  // Функція для реєстрації
  const register = async (values) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${BASE_URL}/api/register/`, values); // Використовуємо BASE_URL
      if (response.status === 200) {
        const { access, refresh } = response.data; // Отримуємо і access, і refresh токени
        setUser({ token: access }); // Зберігаємо токен після реєстрації
        localStorage.setItem('token', access); // Зберігаємо access токен у localStorage
        localStorage.setItem('refresh_token', refresh); // Зберігаємо refresh токен у localStorage
        axios.defaults.headers.common['Authorization'] = `Bearer ${access}`; // Додаємо токен до заголовків запитів
      }
    } catch (err) {
      setError('Registration failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Функція для логіну
  const login = async (values) => {
    console.log('Login function called with values:', values); // Додаємо лог тут
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${BASE_URL}/api/login/`, values); // Використовуємо BASE_URL
      console.log('Response from API:', response); // Додаємо лог тут
      if (response.status === 200) {
        const { access } = response.data;
        setUser({ token: access }); // Зберігаємо токен після входу
        localStorage.setItem('token', access); // Зберігаємо токен у localStorage
        axios.defaults.headers.common['Authorization'] = `Bearer ${access}`; // Додаємо токен до заголовків запитів
        console.log('Login successful, token saved:', access);
      }
    } catch (err) {
      console.error('Login failed:', err); // Лог для помилки
      setError('Login failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Функція для виходу
  const logout = () => {
    setUser(null);
    localStorage.removeItem('token'); // Видаляємо токен із localStorage
    delete axios.defaults.headers.common['Authorization']; // Видаляємо заголовок авторизації
  };

  const refreshToken = async () => {
    try {
      const refresh = localStorage.getItem('refresh_token');
      const response = await axios.post(`${BASE_URL}/api/token/refresh/`, { refresh }); // Використовуємо BASE_URL
      const { access } = response.data;
      localStorage.setItem('token', access);  // Оновлюємо access токен у localStorage
      axios.defaults.headers.common['Authorization'] = `Bearer ${access}`;  // Оновлюємо заголовок
      return access;
    } catch (error) {
      console.error('Refresh token failed:', error);
      logout();  // Якщо оновлення токену не вдається, виходимо із системи
      return null;
    }
  };

  // Отримуємо токен з localStorage, якщо він існує
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setUser({ token });
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, register, login, logout, loading, error }}>
      {children}
    </AuthContext.Provider>
  );
};
